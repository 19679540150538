exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blog-approach-data-annotation-mdx": () => import("./../../../src/pages/blog/approach-data-annotation.mdx" /* webpackChunkName: "component---src-pages-blog-approach-data-annotation-mdx" */),
  "component---src-pages-blog-approach-mdx": () => import("./../../../src/pages/blog/approach.mdx" /* webpackChunkName: "component---src-pages-blog-approach-mdx" */),
  "component---src-pages-blog-chisel-online-abuse-mdx": () => import("./../../../src/pages/blog/chisel-online-abuse.mdx" /* webpackChunkName: "component---src-pages-blog-chisel-online-abuse-mdx" */),
  "component---src-pages-blog-collective-journey-mdx": () => import("./../../../src/pages/blog/collective-journey.mdx" /* webpackChunkName: "component---src-pages-blog-collective-journey-mdx" */),
  "component---src-pages-blog-context-matters-mdx": () => import("./../../../src/pages/blog/context-matters.mdx" /* webpackChunkName: "component---src-pages-blog-context-matters-mdx" */),
  "component---src-pages-blog-cross-platform-pt-1-mdx": () => import("./../../../src/pages/blog/cross-platform-pt1.mdx" /* webpackChunkName: "component---src-pages-blog-cross-platform-pt-1-mdx" */),
  "component---src-pages-blog-cross-platform-pt-2-mdx": () => import("./../../../src/pages/blog/cross-platform_pt_2.mdx" /* webpackChunkName: "component---src-pages-blog-cross-platform-pt-2-mdx" */),
  "component---src-pages-blog-getting-started-with-aws-copilot-mdx": () => import("./../../../src/pages/blog/getting-started-with-aws-copilot.mdx" /* webpackChunkName: "component---src-pages-blog-getting-started-with-aws-copilot-mdx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-making-of-august-2023-mdx": () => import("./../../../src/pages/blog/making-of-August-2023.mdx" /* webpackChunkName: "component---src-pages-blog-making-of-august-2023-mdx" */),
  "component---src-pages-blog-making-of-dec-2021-mdx": () => import("./../../../src/pages/blog/making-of-dec-2021.mdx" /* webpackChunkName: "component---src-pages-blog-making-of-dec-2021-mdx" */),
  "component---src-pages-blog-making-of-dec-2023-mdx": () => import("./../../../src/pages/blog/making-of-dec-2023.mdx" /* webpackChunkName: "component---src-pages-blog-making-of-dec-2023-mdx" */),
  "component---src-pages-blog-making-of-feb-2022-mdx": () => import("./../../../src/pages/blog/making-of-feb-2022.mdx" /* webpackChunkName: "component---src-pages-blog-making-of-feb-2022-mdx" */),
  "component---src-pages-blog-making-of-jan-2022-mdx": () => import("./../../../src/pages/blog/making-of-jan-2022.mdx" /* webpackChunkName: "component---src-pages-blog-making-of-jan-2022-mdx" */),
  "component---src-pages-blog-making-of-mar-2022-mdx": () => import("./../../../src/pages/blog/making-of-mar-2022.mdx" /* webpackChunkName: "component---src-pages-blog-making-of-mar-2022-mdx" */),
  "component---src-pages-blog-responsible-display-mdx": () => import("./../../../src/pages/blog/responsible-display.mdx" /* webpackChunkName: "component---src-pages-blog-responsible-display-mdx" */),
  "component---src-pages-blog-silencing-impact-ogbv-mdx": () => import("./../../../src/pages/blog/silencing-impact-OGBV.mdx" /* webpackChunkName: "component---src-pages-blog-silencing-impact-ogbv-mdx" */),
  "component---src-pages-blog-slurs-occur-every-language-mdx": () => import("./../../../src/pages/blog/slurs-occur-every-language.mdx" /* webpackChunkName: "component---src-pages-blog-slurs-occur-every-language-mdx" */),
  "component---src-pages-blog-tech-4-dev-user-research-lessons-mdx": () => import("./../../../src/pages/blog/tech4dev-user-research-lessons.mdx" /* webpackChunkName: "component---src-pages-blog-tech-4-dev-user-research-lessons-mdx" */),
  "component---src-pages-blog-uli-privacy-mdx": () => import("./../../../src/pages/blog/uli-privacy.mdx" /* webpackChunkName: "component---src-pages-blog-uli-privacy-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-hi-about-mdx": () => import("./../../../src/pages/hi/about.mdx" /* webpackChunkName: "component---src-pages-hi-about-mdx" */),
  "component---src-pages-hi-faq-mdx": () => import("./../../../src/pages/hi/faq.mdx" /* webpackChunkName: "component---src-pages-hi-faq-mdx" */),
  "component---src-pages-hi-index-js": () => import("./../../../src/pages/hi/index.js" /* webpackChunkName: "component---src-pages-hi-index-js" */),
  "component---src-pages-hidden-words-for-instagram-jsx": () => import("./../../../src/pages/hidden-words-for-instagram.jsx" /* webpackChunkName: "component---src-pages-hidden-words-for-instagram-jsx" */),
  "component---src-pages-hidden-words-for-thread-jsx": () => import("./../../../src/pages/hidden-words-for-thread.jsx" /* webpackChunkName: "component---src-pages-hidden-words-for-thread-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ma-about-mdx": () => import("./../../../src/pages/ma/about.mdx" /* webpackChunkName: "component---src-pages-ma-about-mdx" */),
  "component---src-pages-ma-index-js": () => import("./../../../src/pages/ma/index.js" /* webpackChunkName: "component---src-pages-ma-index-js" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-research-mdx": () => import("./../../../src/pages/research.mdx" /* webpackChunkName: "component---src-pages-research-mdx" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */),
  "component---src-pages-ta-about-mdx": () => import("./../../../src/pages/ta/about.mdx" /* webpackChunkName: "component---src-pages-ta-about-mdx" */),
  "component---src-pages-ta-faq-mdx": () => import("./../../../src/pages/ta/faq.mdx" /* webpackChunkName: "component---src-pages-ta-faq-mdx" */),
  "component---src-pages-ta-index-js": () => import("./../../../src/pages/ta/index.js" /* webpackChunkName: "component---src-pages-ta-index-js" */),
  "component---src-pages-team-2021-mdx": () => import("./../../../src/pages/team-2021.mdx" /* webpackChunkName: "component---src-pages-team-2021-mdx" */),
  "component---src-pages-uli-for-ts-mdx": () => import("./../../../src/pages/uli-for-ts.mdx" /* webpackChunkName: "component---src-pages-uli-for-ts-mdx" */),
  "component---src-pages-uli-media-mdx": () => import("./../../../src/pages/uli_media.mdx" /* webpackChunkName: "component---src-pages-uli-media-mdx" */),
  "component---src-pages-user-guide-jsx": () => import("./../../../src/pages/user-guide.jsx" /* webpackChunkName: "component---src-pages-user-guide-jsx" */)
}

